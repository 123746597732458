import React, { useState } from 'react';
import Slider from 'react-slick';
import { IconBackArrow, IconRightArrow, ListingCard, NamedLink } from '../../components';
import { useIntl } from '../../util/reactIntl';
import { cardRenderSizes } from '../SearchPage/SearchResultsPanel/SearchResultsPanel';

import '../../styles/slick/slick.css';
import '../../styles/slick/slick-theme.css';
import css from './ListingPage.module.css';
function RenderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconRightArrow className={css.icon} />
    </div>
  );
}

function RenderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconBackArrow className={css.icon} />
    </div>
  );
}

const sliderSettings = {
  dots: false,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 2000,
  speed: 500,
  slidesToShow: 4, // Display 4 items on desktop
  slidesToScroll: 1, // Scroll 1 item at a time
  variableWidth: false,
  focusOnSelect: false,
  centerMode: false,
  nextArrow: <RenderNextArrow />,
  prevArrow: <RenderPrevArrow />,
  responsive: [
    {
      breakpoint: 1024, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 3,
        variableWidth: false,
      },
    },
    {
      breakpoint: 768, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 2,
        variableWidth: false,
      },
    },
    {
      breakpoint: 580, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 1.4,
        variableWidth: false,
      },
    },
    {
      breakpoint: 480, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 1.2,
        variableWidth: false,
      },
    },
  ],
};

function PopularMoorage(props) {
  const { listings } = props;

  const intl = useIntl();

  const title = intl.formatMessage({ id: 'SimilarMoorage.title' });
  const viewAllMoorage = intl.formatMessage({ id: 'SimilarMoorage.viewAllMoorage' });

  return (
    <div className={css.popularMoorage}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2 className={css.sectionHeadingWithExtraMargin}>{title}</h2>
          </div>
          <div className={css.rightSec}>
            <NamedLink name="SearchPage">{viewAllMoorage}</NamedLink>
          </div>
        </div>
      </div>
      <div className={css.contentWidth}>
        <Slider {...sliderSettings}>
          {listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes(true)}
              setActiveListing={() => {}}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default PopularMoorage;
